.carousel-wrapper {
  width: 100vw;
  height: 530px;
  position: relative;
  margin-left: 0;
  top: 0;
}

.carousel-wrapper * {
  list-style: none;
}
