table.calendar-wrapper {
  width: 100vw;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.month-indicator {
  position: absolute;
  display: inline-block;
  left: 0;
}

tr.calendar-day-name-of-the-week {
  height: 50px;
}

td.calendar-date-cell {
  max-width: 14vw;
  width: 14vw;
  height: 70px;
  vertical-align: top;
}

.calendar-money {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  font-size: 10px;
  width: 100%;
  position: relative;
  top: 5px;
}

.calendar-income {
  color: red;
}

.calendar-expenditure {
  color: blue;
}

.calendar-save {
  color: #24ff3d;
}

.calendar-date-cell-touching {
  color: rgba(147, 147, 147, 0.4);
}

.calendar-date-cell-selected {
  background-color: #fefd48;
}

.calendar-date-cell-holiday {
  color: red;
}
