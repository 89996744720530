html::-webkit-scrollbar,
html *::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body > #root {
  height: 100vh;
  height: calc(100svh - 55px);
}

.page-wrapper {
  height: 100%;
  width: 100vw;
  position: absolute;
}

#page-contents-wrapper {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 70px;
  height: calc(100svh - 55px);
}

.transitions-wrapper {
  position: relative;
}

.switch-enter {
  opacity: 0 !important;
}

.switch-enter-active {
  opacity: 1 !important;
  transition: all 0.4s ease !important;
}
.switch-exit {
  opacity: 1;
}
.switch-exit-active {
  opacity: 0;
  transition: all !important;
}

.none-enter {
  opacity: 0 !important;
}

.none-enter-active {
  opacity: 1 !important;
  transition: all 0s ease !important;
}
.none-exit {
  opacity: 1;
}
.none-exit-active {
  opacity: 0;
  transition: all !important;
}

.push-enter {
  transform: translateX(100%);
  z-index: 1;
}
.push-enter-active {
  transform: translateX(0);
  transition: all 0.4s ease;
  z-index: 1;
}

.push-exit {
  transform: translateX(0);
  z-index: 0;
}
.push-exit-active {
  transform: translateX(-100%);
  transition: all 0.4s ease;
  z-index: 0;
}

.pop-enter {
  transform: translateX(-100%);
  z-index: 1;
}
.pop-enter-active {
  transform: translateX(0);
  transition: all 0.4s ease;
  z-index: 1;
}

.pop-exit {
  transform: translateX(0);
  z-index: 0;
}
.pop-exit-active {
  transform: translateX(100%);
  transition: all 0.4s ease;
  z-index: 0;
}

.app-input-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-input-form .app-input-box {
  text-align: center;
  width: 300px;
  margin-bottom: 20px;
  margin-top: 20px;
}
