@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");

/* font */
.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.login-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-page {
  text-align: center;
}

.app-input-form .signup-prompt {
  margin-top: 40px;
}

.anchor-button {
  cursor: pointer;
}
