/*
 * transition 
 */
.datepicker-modal-enter {
  opacity: 0 !important;
}

.datepicker-modal-enter-active {
  opacity: 1 !important;
  transition: all 1.5s ease !important;
}
.datepicker-modal-exit {
  opacity: 1;
}
.datepicker-modal-exit-active {
  opacity: 0;
  transition: all !important;
}
