.app-header {
  width: 100vw;
  height: 70px;
  top: 0;
  position: fixed;
  background-color: #fff;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header-item {
  width: 36vw;
  font-size: 15px;
  text-align: center;
}

.app-header-item-right {
  text-align: right;
  width: 32vw;
}

.app-header-item-left {
  text-align: left;
  width: 32vw;
}

.app-header-icon-button {
  font-size: 35px;
}
